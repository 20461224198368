import React, { useEffect, useState } from "react";
import CustomTable from "components/Shared/CustomTable";
import MovementsProductTable from "./MovementsProductTable";
import CustomSelect from "components/Shared/CustomSelect";
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Container,
  Spinner,
  Form,
} from "react-bootstrap";
function MovementsModal(props) {
  useEffect(() => {
    // set the width of the modal
    document.documentElement.style.setProperty("--modal-width", "1200px");
  }, []);

  var hasPermission = false;
  var permissions = localStorage.getItem("permissions");

  if (permissions.includes("Movements.Edit")) {
    hasPermission = true;
  }

  //   props.setSelectedFromLocation(
  //       props.getLocationDropdown.find((location) => {
  //         return location.value === props.SingleMovement.fromLocationName;
  //       })?.id
  //     );
  //     props.setSelectedToLocation(
  //       props.getLocationDropdown.find((location) => {
  //         return location.value === props.SingleMovement.toLocationName;
  //       })?.id
  //     );

  useEffect(() => {
    props.setSelectedFromLocation(
      props.getLocationDropdown.find((location) => {
        return location.value === props.SingleMovement.fromLocationName;
      })?.id
    );
    props.setSelectedToLocation(
      props.getLocationDropdown.find((location) => {
        return location.value === props.SingleMovement.toLocationName;
      })?.id
    );
  }, [props.SingleMovement]);

  return (
    <>
      {alert}
      <Container fluid className="PurchaseModal">
        <Col md="12">
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-end">
                {!props.isEditMovement ? (
                  <Button
                    className="btn-link btn-xs btn-action"
                    variant="danger"
                    onClick={() => props.handleEditMovement()}
                  >
                    <i className="fas fa-edit"></i>
                  </Button>
                ) : (
                  <>
                    <Button onClick={props.handleEditMovement} variant="info">
                      Cancel
                    </Button>
                    <Button
                      onClick={() =>
                        props.handleSaveChangeLocations(
                          props.selectedFromLocation,
                          props.selectedToLocation
                        )
                      }
                    >
                      Save
                    </Button>
                  </>
                )}
              </div>
              <Row>
                <Col md="4">
                  <Form.Group>
                    <label>Batch Number</label>
                    <Form.Control
                      placeholder="Batch Number"
                      type="text"
                      value={props.SingleMovement.batchName}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <label>Location From</label>
                    {!props.isEditMovement ? (
                      <Form.Control
                        placeholder="Location From"
                        type="text"
                        value={props.SingleMovement.fromLocationName}
                        disabled
                      ></Form.Control>
                    ) : (
                      <CustomSelect
                        placeholder="Location from"
                        data={props.getLocationDropdown}
                        handleChange={(e) => {
                          props.setSelectedFromLocation(e.value);
                        }}
                        withAll={false}
                        id={props.selectedFromLocation}
                      />
                    )}
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <label>Location To</label>
                    {!props.isEditMovement ? (
                      <Form.Control
                        placeholder="Location To"
                        type="text"
                        value={props.SingleMovement.toLocationName}
                        disabled
                      ></Form.Control>
                    ) : (
                      <CustomSelect
                        placeholder=" Location to"
                        data={props.getLocationDropdown}
                        handleChange={(e) => {
                          props.setSelectedToLocation(e.value);
                        }}
                        withAll={false}
                        id={props.selectedToLocation}
                      />
                    )}
                  </Form.Group>
                              </Col>
                              {/*<Col md="3">*/}
                              {/*    <Form.Group>*/}
                              {/*        <label>User</label>*/}
                              {/*        <Form.Control*/}
                              {/*            placeholder="User"*/}
                              {/*            type="text"*/}
                              {/*            value={props.SingleMovement.userName}*/}
                              {/*            disabled*/}
                              {/*        ></Form.Control>*/}
                              {/*    </Form.Group>*/}
                              {/*</Col>*/}
              </Row>
              <Row>
                <Col md="4">
                  <Form.Group>
                    <label>Type</label>
                    <Form.Control
                      placeholder="Type"
                      type="text"
                      value={props.SingleMovement.typeName}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <label>Date Created</label>
                    <Form.Control
                      placeholder="Date Created"
                      type="text"
                      value={new Date(
                        props.SingleMovement.dateCreated
                      ).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                      })}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <label>Date Amended</label>
                    <Form.Control
                      placeholder="Date Amended"
                      type="text"
                      value={new Date(
                        props.SingleMovement.dateAmended
                      ).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                      })}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Container>

      <Container fluid className="PurchaseModalTable">
        <Row>
          <Col md="12">
            <MovementsProductTable
              SingleMovement={props.SingleMovement}
              MovementsProductData={props.MovementsProductData}
              isLoading={props.isLoading}
              handleSearchChange={props.handleSearchChange}
              search={props.search}
              handleAddMovementProduct={props.handleAddMovementProduct}
              handleGetItemCard={props.handleGetItemCard}
              handleMassBookIn={props.handleMassBookIn}
              userPermissions={props.userPermissions}
              handleRemoveMovementProductLine={
                props.handleRemoveMovementProductLine
              }
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default MovementsModal;
