import React, {useEffect, useState} from "react";
import CustomTable from "components/Shared/CustomTable";
import {
    Button,
    Card,
    Col,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
    Container,
    Spinner,
    Form,
    CardHeader,
} from "react-bootstrap";

function ApproveAdjustmentsModal(props) {
    const {additionalCost, additionalUnits, writeOffCost, writeOffUnits} =
        props.adjustmentCalculationsData;

    useEffect(() => {
        // set the width of the modal
        document.documentElement.style.setProperty("--modal-width", "600px");
    }, []);

    var hasPermission = false;
    var permissions = localStorage.getItem("permissions");

    return (
        <>
            {alert}
            <Container fluid className="PurchaseModal">
                <Col md="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-center">
                            <Card.Title as="h4">Approve Adjustments</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            {!props.stocktakeConfirming ? (
                                <div>
                                    {writeOffUnits && writeOffCost ? (
                                        <div className="d-flex justify-content-start align-items-center mb-3">
                                            <i className="fas fa-exclamation-triangle me-2"></i>
                                            <span>
                                                You are about to write off {writeOffUnits} units at a total cost of: £
                                                {writeOffCost.toFixed(2)}
                                            </span>
                                        </div>
                                    ) : null}
                                    {additionalUnits && additionalCost ? (
                                        <div className="d-flex justify-content-start align-items-center">
                                            <i className="fas fa-exclamation-triangle me-2"></i>
                                            <span>
                                                You are about to add {additionalUnits} units at a total cost of: £
                                                {additionalCost.toFixed(2)}
                                            </span>
                                        </div>
                                    ) : null}
                                    {!writeOffUnits && !additionalUnits && (
                                        <div className="d-flex justify-content-start align-items-center">
                                            <span>There are no adjustments being made</span>
                                        </div>
                                    )}

                                    <div>
                                        <Form.Group>
                                            <Form.Label>Notes</Form.Label>
                                            <Form.Control
                                                style={{height: "8rem"}}
                                                as="textarea"
                                                rows={10}
                                                value={props.adjustmentNotes}
                                                onChange={(e) => props.setAdjustmentNotes(e.target.value)}
                                            ></Form.Control>
                                        </Form.Group>
                                    </div>

                                    <div className="d-flex flex-column justify-content-center py-3">
                                        <div className="d-flex w-100 justify-content-center pb-3">
                                            <Form.Check className="pl-0">
                                                <Form.Check.Label>
                                                    <Form.Check.Input
                                                        defaultValue=""
                                                        type="checkbox"
                                                        checked={props.hasConsentedToAdjustments}
                                                        onChange={props.handleConsentApproveAdjustments}
                                                    ></Form.Check.Input>
                                                    <span className="form-check-sign"></span>
                                                    I confirm these changes
                                                </Form.Check.Label>
                                            </Form.Check>
                                        </div>
                                    </div>

                                    <Row className="d-flex justify-content-center">
                                        <Button
                                            onClick={() => props.handleConfirmApproveAdjustments()}
                                            disabled={!props.hasConsentedToAdjustments}
                                        >
                                            Confirm
                                        </Button>
                                    </Row>
                                </div>
                            ) : (
                                <div style={{ height: 300 }} className="d-flex justify-content-center align-items-center text-center">
                                    <div>
                                        <Spinner animation="border" />
                                        <p>Loading...</p>
                                    </div>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Container>
        </>
    );
}

export default ApproveAdjustmentsModal;
