import React, {useEffect, useState} from "react";
import {
    OverlayTrigger,
    Table,
    Card,
    Col,
    Row,
    Button,
    Form,
    Tooltip,
} from "react-bootstrap";
import {showSweetAlert} from "components/Shared/CustomAlert";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomModal from "../Shared/CustomModal";
import ItemCard from "../ItemCard/ItemCard";
import CustomSelect from "../Shared/CustomSelect";
import ModalContainer from "../Shared/ModalContainer";

const StocktakeLinesTable = (props) => {
    let stocktakeId = null;
    const [stocktakeLines, setStocktakeLines] = useState(props.data);
    const originalStocktakeLines = props.data;
    const [selectedStatusId, setSelectedStatusId] = useState(0);
    const statusList = [
        {id: 0, value: "All"},
        {id: 1, value: "Items Missing"},
        {id: 2, value: "Partial Match"},
        {id: 3, value: "Matched Expectation"},
        {id: 4, value: "Unexpected Product"},
    ]

    const [updatedPage, setUpdatedPage] = useState(false);

    const [show0Variance, setShow0Variance] = useState(true);
    const handleShow0Variance = () => {
        setShow0Variance((prev) => !prev);
    };

    const [showUnscannedProducts, setShowUnscannedProducts] = useState(true);
    const handleShowUnscannedProducts = () => {
        setShowUnscannedProducts((prev) => !prev);
    };

    const [showSoldProducts, setShowSoldProducts] = useState(false);
    const handleShowSoldProducts = () => {
        setShowSoldProducts((prev) => !prev);
    };

    const [showIntransitProducts, setShowIntransitProducts] = useState(false);
    const handleShowIntransitProducts = () => {
        setShowIntransitProducts((prev) => !prev);
    };


    useEffect(() => {
        if (selectedStatusId == 0)
            setStocktakeLines(originalStocktakeLines)
        else {
            console.log(selectedStatusId);
            console.log(statusList);
            console.log("res", statusList.filter(item => item.id === selectedStatusId))
            const statusName = statusList.filter(item => item.id === selectedStatusId)[0]?.value.toLowerCase();
            setStocktakeLines(originalStocktakeLines.filter(item => item.statusName?.toLowerCase() === statusName))
        }
    }, [selectedStatusId]);


    useEffect(() => {
        recalculateStocktakeLines();
    }, [show0Variance, showUnscannedProducts, showSoldProducts, showIntransitProducts, props.data]);

    const recalculateStocktakeLines = () => {
        let newLines = props.data;

        if (!show0Variance) {
            newLines = newLines.filter((item) => item.onHand - item.sold - item.totalStock !== 0);
        }

        if (!showUnscannedProducts) {
            newLines = newLines.filter((item) => item.scanned !== 0);
        }

        if (showSoldProducts) {
            newLines = newLines.filter((item) => item.sold !== 0);
        }
        
        if (showIntransitProducts) {
            newLines = newLines.filter((item) => item.inTransit !== 0);
        }

        setStocktakeLines(newLines);
    };

    if (props.data && props.data.length > 0)
        stocktakeId = props.data[0].stocktakeId;

    const checkPageUpdated = () => {
        if (updatedPage)
            showSweetAlert(
                "warningOkOnly",
                "Please save the changes before confirming the stocktake"
            );
        else props.handleConfirmStocktake(stocktakeId);
    };

    const handleSaveStocktake = () => {
        setUpdatedPage(false);
        props.handleSaveVariances();
    };

    // item card properties
    //const [selectedProductId, setSelectedProductId] = useState(0);

    //const handleShowInventoryModal = (item) => {
    //    setSelectedInventory(item);
    //    setShowInventoryViewModal(true);
    //    setShowItemCardModal(false);
    //};

    //const handleItemCardCloseModal = () => {
    //    //setShowItemCardModal(false);
    //    setSelectedProductId(0);
    //};

    //const handleHideInventoryModal = () => {
    //    setShowInventoryViewModal(false);
    //    setShowItemCardModal(true);
    //};

    //const handleGetItemCard = (id) => {
    //    setSelectedProductId({ id: id, first: "itemCard" });
    //    //setShowItemCardModal(true);
    //    //setShowInventoryViewModal(false);
    //};

    
    const returnTableData = () => {
        return stocktakeLines.map((stocktake, index) => {
            if (stocktake === null || stocktake === undefined) return;

            const {
                id,
                stocktakeId,
                productId,
                productName,
                checkRequired,
                checkNotes,
                scanned,
                totalStock,
                variance,
                adjustment,
                onHand,
                committed,
                inTransit,
                sold,
                brandCode,
                colour,
                size,
                barcode,
                sku,
                adjustmentAction,
                adjustmentCost,
                difference = onHand - sold - totalStock,
                statusName,
                minValue = Math.min(onHand, 0)
            } = stocktake;

            return (
                <tr key={index}>
                    <td onClick={() => props.handleGetItemCard(productId)}>
                        <a href="#id">{sku}</a>
                    </td>
                    <td>{productName}</td>
                    <td>{size}</td>
                    <td>{colour}</td>
                    <td>{brandCode}</td>
                    <td>{barcode}</td>
                    <td>{onHand}</td>
                    <td>
                        {inTransit > 0 ? (
                            <a
                                href="#id"
                                onClick={() =>
                                    props.handleInTransitClick(
                                        "InTransit",
                                        productId,
                                        props.stocktakeLocationId
                                    )
                                }
                            >
                                {inTransit}
                            </a>
                        ) : (
                            inTransit
                        )}
                    </td>
                    <td>{sold}</td>
                    <td>
                        <div>{scanned <= 0 ? "Not scanned" : scanned}</div>
                    </td>
                    <td>
                        <div className="d-flex justify-content-between align-items-center">
                            {
                                props.stocktakeStatus !== "Complete" &&
                                <i
                                    className={`fas fa-minus ${totalStock <= minValue ? "iconDisabled" : ""}`}
                                    style={{
                                        cursor: totalStock > minValue ? "pointer" : "not-allowed",
                                        opacity: totalStock > minValue ? 1 : 0.5,
                                    }}
                                    onClick={() => {
                                        if (totalStock > minValue) {
                                            setUpdatedPage(true);
                                            props.handleAdjustTotal(id, totalStock - 1);
                                        }
                                    }}
                                />
                            }
                            {totalStock} (
                            {difference > 0
                                ? `${difference} missing`
                                : difference < 0
                                    ? `${Math.abs(difference)} extra`
                                    : "perfect"}
                            )
                            {
                                props.stocktakeStatus !== "Complete" &&
                                <i
                                    className="fas fa-plus pr-1"
                                    style={{cursor: "pointer"}}
                                    onClick={() => {
                                        setUpdatedPage(true);
                                        props.handleAdjustTotal(id, totalStock + 1);
                                    }}
                                />
                            }
                        </div>
                    </td>
                    <td>{statusName}</td>
                    <td style={{width: "10px"}}>
                        <div className="d-flex">
                            {checkRequired && (
                                <OverlayTrigger
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                    overlay={
                                        <Tooltip id="tooltip-255158527">{checkNotes}</Tooltip>
                                    }
                                >
                                    <Button
                                        className="btn-wd btn-outline mr-1"
                                        type="button"
                                        variant="warning"
                                    >
                                        <span className="btn-label">
                                          <i className="fas fa-exclamation-triangle"></i>
                                        </span>
                                    </Button>
                                </OverlayTrigger>
                            )}
                        </div>
                    </td>
                </tr>
            );
        });
    };

    return (
        <>
            
            <Card className="strpied-tabled-with-hover">
                <Card.Header>
                    <Card.Title className="d-flex justify-content-between align-items-center">
                        <h4>Stocktake Lines</h4>

                        <div className="d-flex align-items-center justify-content-end flex-grow-1 mx-3">
                            <Row className="d-flex align-items-center justify-content-end">
                                <Col
                                    className="d-flex justify-content-end align-items-center"
                                    style={{maxWidth: "auto"}} // Ensure the "Status" label doesn't take extra space
                                >
                                    <b>Status</b>
                                </Col>
                                <Col
                                    style={{width: "300px"}}
                                    className="ms-2" // Add some spacing between "Status" and the select box
                                >
                                    <CustomSelect
                                        id={selectedStatusId}
                                        data={statusList}
                                        handleChange={(e) => setSelectedStatusId(e.value)}
                                        withAll={false}
                                    />
                                </Col>
                            </Row>

                            <Form.Check className="pe-3 ms-3">
                                <Form.Check.Label>
                                    <Form.Check.Input
                                        defaultValue=""
                                        type="checkbox"
                                        checked={show0Variance}
                                        onChange={() => handleShow0Variance()}
                                    />
                                    <span className="form-check-sign"></span>
                                    Show 0 Variance
                                </Form.Check.Label>
                            </Form.Check>
                            <Form.Check className="pe-3">
                                <Form.Check.Label>
                                    <Form.Check.Input
                                        defaultValue=""
                                        type="checkbox"
                                        checked={showUnscannedProducts}
                                        onChange={() => handleShowUnscannedProducts()}
                                    />
                                    <span className="form-check-sign"></span>
                                    Show Unscanned Products
                                </Form.Check.Label>
                            </Form.Check>
                            <Form.Check className="pe-3">
                                <Form.Check.Label>
                                    <Form.Check.Input
                                        defaultValue=""
                                        type="checkbox"
                                        checked={showSoldProducts}
                                        onChange={() => handleShowSoldProducts()}
                                    />
                                    <span className="form-check-sign"></span>
                                    Show Sold
                                </Form.Check.Label>
                            </Form.Check>
                            <Form.Check className="pe-3">
                                <Form.Check.Label>
                                    <Form.Check.Input
                                        defaultValue=""
                                        type="checkbox"
                                        checked={showIntransitProducts}
                                        onChange={() => handleShowIntransitProducts()}
                                    />
                                    <span className="form-check-sign"></span>
                                    Show In Transit
                                </Form.Check.Label>
                            </Form.Check>
                        </div>

                        <Button
                            className="btn-fill"
                            variant="primary"
                            disabled={props.stocktakeStatus === "Complete"}
                            onClick={() => checkPageUpdated()}
                        >
                            Confirm Stocktake
                        </Button>
                    </Card.Title>
                </Card.Header>

                {/* todo - if there is data BUT its also 0 scanned then show diff message */}
                <Card.Body className="table-responsive p-0" style={{height: 650}}>
                    {stocktakeLines && stocktakeLines.length == 0 && !props.isLoading && (
                        <p
                            style={{height: 650}}
                            className="d-flex justify-content-center align-items-center text-center"
                        >
                            No data found.
                        </p>
                    )}
                    {stocktakeLines && stocktakeLines.length > 0 && !props.isLoading && (
                        <Col md="12">
                            <InfiniteScroll
                                dataLength={props.data.length}
                                height={650}
                            >
                                <Table className="table-hover table-striped w-full">
                                    <thead style={{zIndex: "1000"}}>
                                    <tr>
                                        <th>SKU</th>
                                        <th>Name</th>
                                        <th>Size</th>
                                        <th>Colour</th>
                                        <th>Brand Code</th>
                                        <th>Barcode</th>
                                        <th>On Hand</th>
                                        <th>In Transit</th>
                                        <th>Sold</th>
                                        <th>Scanned</th>
                                        <th>Total</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>{returnTableData()}</tbody>
                                </Table>
                            </InfiniteScroll>
                        </Col>
                    )}
                </Card.Body>
                <Card.Footer className="d-flex justify-content-end">
                    <Button
                        className="btn-fill"
                        variant="primary"
                        disabled={props.stocktakeStatus === "Complete"}
                        onClick={() => handleSaveStocktake()}
                    >
                        Save
                    </Button>
                </Card.Footer>
            </Card>
        </>
    );
};

export default StocktakeLinesTable;
